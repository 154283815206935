import ApiService from "@/services/api_service"
import {IApiResponse, IData, IUser} from "@/types"
import {TokenService} from "@/services/storage_service"
import qs from "qs"

type TicketsFilter = {
  type: string
}

enum ACTIONS {
  GET_TICKETS = 'get_boardview_grid',
  TOGGLE_STEP_EXPANDED = 'set',
  MOVE_TICKET = 'move_ticket',
  REMOVE_TICKET = 'remove_ticket',
  MOVE_TICKET_FIRST = 'move_ticket_first',
  MOVE_TICKET_TO_NEXT = 'move_ticket_to_next',
  SET_TICKET_COLOR = 'set_ticket_color',
  ASSIGN_TICKET_TO_ME = 'assign_ticket_to_me',
  ARCHIVE_TICKET = "archive_ticket"
}

const BoardService = {
  _axios: function (params, cancelTokenId, method?): Promise<any> {
    ApiService.cancelRequest("get_tickets");
    return new Promise((resolve, reject) => {
      return ApiService.request({
        url: '/action.php',
        method: method || "get",
        params: params
      }, cancelTokenId).then(response => {
        if (response.xdata.isError) {
          reject(response.xdata.message);
        } else {
          resolve(response?.xdata)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  get_tickets: function (projectId: number, filter?: TicketsFilter, sortBy?: string): Promise<any> {
    const params = {
      action: ACTIONS.GET_TICKETS,
      project: projectId,
    }
    const cancelTokenId = ACTIONS.GET_TICKETS
    return this._axios(params, cancelTokenId, 'post');
  },

  get_tickets_by_step: function (projectId: number, stepId: string, filter?: TicketsFilter, sortBy?: string): Promise<any> {
    ApiService.cancelRequest("get_tickets_by_step");
    return new Promise((resolve, reject) => {
      const params = {
        action: 'get_boardview_grid',
        project: projectId,
        stepId: stepId
      }
      const cancelTokenId = 'get_tickets_by_step'
      ApiService.request({
        url: '/action.php',
        method: "get",
        params: params
      }, cancelTokenId).then(response => {
        // console.log('get tickets success', response)
        resolve(response.xdata)
      }).catch(error => {
        reject(error)
        // console.log('get tickets error ', error.message)
      })
    })
  },

  get_custom_fields: function (projectId: string | number): Promise<any> {
    ApiService.cancelRequest("get_custom_fields");
    return new Promise((resolve, reject) => {
      const params = {
        action: 'get_fields',
        obj_type: 'board_view',
        project: projectId,
      }
      const cancelTokenId = 'get_custom_fields'

      ApiService.request({
        url: '/action.php',
        method: "get",
        params: params
      }, cancelTokenId).then(response => {
        // console.log('get tickets success', response)
        resolve(response)
      }).catch(error => {
        reject(error)
        // console.log('get tickets error ', error.message)
      })
    })
  },

  set_custom_fields: function (projectId: string | number, fields): Promise<any> {
    ApiService.cancelRequest("set_custom_fields");
    return new Promise((resolve, reject) => {
      const params = {
        action: 'set_fields',
        obj_type: 'board_view',
        project: projectId,
        orders: fields.orders,
        fields: fields.visible
      }
      const cancelTokenId = 'set_custom_fields'

      ApiService.request({
        url: '/action.php',
        method: "post",
        data: qs.stringify(params)
      }, cancelTokenId).then(response => {
        // console.log('get tickets success', response)
        resolve(response.xdata.ret)
      }).catch(error => {
        reject(error)
        // console.log('get tickets error ', error.message)
      })
    })
  },

  toggle_step_expanded: function (stepId: number, projectId, expanded: boolean): Promise<any> {
    const params = {
      action: ACTIONS.TOGGLE_STEP_EXPANDED,
      step: stepId,
      project: projectId,
      open: expanded ? 1 : 0
    }
    return this._axios(params, ACTIONS.TOGGLE_STEP_EXPANDED + '_step_expanded_' + stepId, 'post');
  },

  move_ticket: function (id: string, stepId: string|number, pos: string, prevId?: string, nextId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.MOVE_TICKET,
        id: id,
        flow_step_id: stepId,
        pos: pos,
        prev_ticket_id: prevId,
        next_ticket_id: nextId,
      }
      return this._axios(params, ACTIONS.MOVE_TICKET, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  move_ticket_first: function (id: string, toEnd?:boolean): Promise<any> {
    console.log('move_ticket_first', id, toEnd)
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.MOVE_TICKET_FIRST,
        id: id,
        //to_end: toEnd || false
      }
      if (toEnd) {
        params['to_end'] = toEnd
      }
      return this._axios(params, ACTIONS.MOVE_TICKET_FIRST, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  move_ticket_to_next: function (id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.MOVE_TICKET_TO_NEXT,
        id: id
      }
      return this._axios(params, ACTIONS.MOVE_TICKET_TO_NEXT, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  assign_ticket_to_me: function (id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.ASSIGN_TICKET_TO_ME,
        id: id
      }
      return this._axios(params, ACTIONS.ASSIGN_TICKET_TO_ME, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  archive_ticket: function (id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.ARCHIVE_TICKET,
        id: id
      }
      return this._axios(params, ACTIONS.ARCHIVE_TICKET, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  set_ticket_color: function (id: string, color: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: ACTIONS.SET_TICKET_COLOR,
        id: id,
        color: color
      }
      return this._axios(params, ACTIONS.SET_TICKET_COLOR, 'post').then((response)=>resolve(response)).catch((error)=>reject(error));
    })
  },

  quick_save_ticket: function (projectId: string, stepId: string, name: string, coverFile?): Promise<any> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_ticket'

      const params: IData = {
        action: actionName,
        quick_add: '1',
        flow_id: projectId,
        flow_step_id: stepId,
        title: name
      }
      const bodyFormData = new FormData()
      if (coverFile) {
        bodyFormData.append('cover', coverFile)
        Object.assign(params, {
          paste: '1',
        })
      }

      ApiService.request({
        method: "post",
        params: params,
        data: bodyFormData
        //data: qs.stringify(params),
      }).then((data) => {
        console.log('BoardService quick_save_ticket success', data)
        resolve(data)
      }).catch((data) => {
        console.log('BoardService quick_save_ticket error', data)
        reject(data)
      })
    })

/*
    return new Promise((resolve, reject) => {
      const params = {
        site_ver: 'v2',
        action: 'save_ticket',
        type: 'json',
        quick_add: '1',
        flow_id: projectId,
        flow_step_id: stepId,
        title: name
      }
      const bodyFormData = new FormData();
      if (coverFile) {
        bodyFormData.append('cover', coverFile)
        Object.assign(params, {
          paste: '1',
        })
      }
      ApiService._axios({
        url: '/action.php',
        method: "post",
        params: params,
        data: bodyFormData
      }).then(response => {
        if (response.data.isError) {
          reject(response?.data?.xdata);
        } else {
          resolve(response?.data?.xdata)
        }
      }).catch(error => {
        reject(error)
      })
    })
*/
  },

  delete_ticket: function (id): Promise<any> {
    const params = {
      action: ACTIONS.REMOVE_TICKET,
      id: id
    };
    return this._axios(params, ACTIONS.REMOVE_TICKET, 'post');
  },

  get_last_note: function (id: number|string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'get_last_note',
        id: id
      }
      ApiService.request({
        responseType: 'json',
        method: "post",
        params: params,
      }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default BoardService
