import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import GlobalService from "@/services/global_service";
import {ISettings} from "@/types";

// export interface SettingsState {
//   // ----- main ------
//   hide_old_object_period      ?: string,
//   ticket_email_notify         ?: string,
//   note_email_notify           ?: string,
//   notify_from_email_integr    ?: string,
//   def_users_ext_project       ?: string,
//   user_send_new_ticket_email  ?: string,
//   forwarding_email            ?: string,
//   password_strong             ?: string,
//   // ----- personal -----
//
//   // ----- view ------
//   time_format                 ?: string
//   time_zone                   ?: string
//   bv_show_star                ?: string
//   tags_align                  ?: 'hidden'|'left'|'right'|'after'
//   show_cover                  ?: string
//   show_ticket_id              ?: string
//   bw_tickets_expanded         ?: string
//   bw_user_field               ?: string
//   bw_date_field               ?: string
//   bv_show_todo                ?: string
//   bv_show_notes               ?: string
//   sticker_dark_color          ?: number
//   bw_user_team_opts           ?: string,
//
//   show_avatar_background      ?: string
//   lazy_on                     ?: string
//
//   //----- access control ------
//   access_type                 ?: string, // none | country
//   allowed_cntrs               ?: [],
//   allowed_ips                 ?: []
//
// }

const state: ISettings = {
  // ----- main ------

  // ----- personal -----

  // ----- view ------
  time_format: "g:i a",
  time_zone: "Europe/Kiev",
  bv_show_star: "1",
  tags_align: 'hidden',
  show_cover: "0",
  show_ticket_id: "0",
  bw_user_field: "0",
  bw_date_field: "0",
  bv_show_todo: "0",
  bv_show_notes: "0",
  sticker_dark_color: 0,
  bw_user_team_opts: "10",

  lazy_on: (localStorage.getItem('lazy_on') || "1"),
  show_avatar_background: (localStorage.getItem('show_avatar_background') || '0'),

  //----- access control ------
  access_type:    "country", // none | country
  allowed_cntrs:  [],
  allowed_ips:    []
}

const mutations: MutationTree<ISettings> = {
  setTicketView(state, settings) {
    Object.assign(state, settings);
  },

  setSettings(state, settings) {
    console.log('set settings ', state, settings)
    Object.assign(state, settings);
  },

  setShowAvatarBackground(state, val) {
    localStorage.setItem('show_avatar_background', val.show_avatar_background)
    Object.assign(state, val);
  },

  setLazyOn(state, val) {
    localStorage.setItem('lazy_on', val.lazy_on)
    Object.assign(state, val);
  }
}

const actions: ActionTree<ISettings, RootState> = {
  load({commit}) {
    return new Promise(()=>{
      GlobalService.get_main().then((response)=>{
        console.log('response');
        commit('setSettings', response.model.settings);
      })
    });
  }
}

const getters: GetterTree<ISettings, RootState> = {
  time_zone: state=>state.time_zone,
  time_format: state=>state.time_format,
  //bv_show_star: state=>false,
  bv_show_star: state=>state.bv_show_star == "1",
  tags_align: state=>state.tags_align,
  show_cover: state=>state.show_cover == "1",
  show_ticket_id: state=>state.show_ticket_id == "1",
  //show_ticket_id: state=>"1",
  bw_tickets_expanded: state=>state.bw_tickets_expanded,
  bw_user_field: state=>"2",
  // bw_user_field: state=>state.bw_user_field,
  bw_date_field: state=>state.bw_date_field,
  bv_show_todo: state=>state.bv_show_todo == "1",
  bv_show_notes: state=>state.bv_show_notes == "1",
  dark_color: state=>state.sticker_dark_color,
  show_avatar_background: state=>state.show_avatar_background,
  // bw_user_field_type: state=>state.bw_user_field_type,
  lazy_on: state=>state.lazy_on,


  getAll: (state) => {
    return {...state};
  },

  ticketView: (state)=>{
    return {
      show_ticket_id: state.show_ticket_id == "1",
      bw_tickets_expanded: state.bw_tickets_expanded,
      bv_show_star: state.bv_show_star == "1",
      show_cover: state.show_cover == "1",
      bw_user_field: state.bw_user_field,
      bw_date_field: state.bw_date_field,
      bv_show_todo: state.bv_show_todo == "1",
      bv_show_notes: state.bv_show_notes == "1",
      // bw_user_field_type: state.bw_user_field_type,
      show_avatar_background: state.show_avatar_background,
      tags_align: state.tags_align,
      dark_color: state.sticker_dark_color,
    };

  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
